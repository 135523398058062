<template>

    <ejs-dialog ref="dialog" :position="position" :header="header" :visible="dialogVisible" width="550" :buttons="buttons" :isModal="true" @open="dialogOpened" @beforeClose="beforeClose">
        <Spinner v-show="saving" message="Saving"></Spinner>
        <div v-show="!saving" id="form-element">
            <div v-if="newUser" class="row mb-2">
                <div class="col">
                    <label>New User</label>
                    <ejs-dropdownlist ref="ddlNewUser"
                                      :dataSource="users.dataSource"
                                      :query="users.query"
                                      :fields="users.fields"
                                      :allowFiltering="true"
                                      filterType="Contains"
                                      placeholder="Select a user"
                                      popupWidth="Auto"
                                      @change="users.change"
                                      @filtering="users.filtering"
                                      :itemTemplate="'userTemplate'">
                        <template v-slot:userTemplate="{data}">
                            <UserTemplate :userInfo="data" />
                        </template>
                    </ejs-dropdownlist>
                </div>
            </div>
            <div class="row mb-2 gx-2">
                <div class="col-md-2 col-sm-2">
                    <img :src="avatar" class="img-thumbnail avatar" alt="user profile image">
                </div>
                <div class="col-md-5 col-sm-5">
                    <label>First Name</label>
                    <ejs-textbox placeholder="John" name="FirstName" :maxLength="100" :enabled="false" v-model:value="userModel.FirstName"></ejs-textbox>
                </div>
                <div class="col-md-5 col-sm-5">
                    <label>Last Name</label>
                    <ejs-textbox placeholder="Doe" name="LastName" :maxLength="150" :enabled="false" v-model:value="userModel.LastName"></ejs-textbox>
                </div>
            </div>
            <div class="row mb-2">
                <div v-if="userModel.NewNetid" class="col">
                    <label>NetID</label>
                    <ejs-textbox v-if="userModel.NewNetid" placeholder="xzxxxx" name="Netid" :enabled="false" v-model:value="userModel.NewNetid"></ejs-textbox>
                    <ejs-textbox v-else-if="userModel.Netid" placeholder="xzxxxx" name="Netid" :enabled="false" v-model:value="userModel.Netid"></ejs-textbox>
                    <ejs-textbox v-else placeholder="xzxxxx" name="Netid" :maxLength="6" :enabled="!!userModel.GlobalUserName" v-model:value="tempNetid" autocomplete="off"></ejs-textbox>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col">
                    <label>Email</label>
                    <ejs-textbox placeholder="john.doe@nexteer.com" name="Email" :enabled="false" :maxLength="350" v-model:value="userModel.Email"></ejs-textbox>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col">
                    <label>Tablet Password <i class="fa fa-info-circle" title="This password can only be used when logging in with the user's NetID on the tablets."></i></label>
                    <ejs-textbox placeholder="Tablet Password" name="Password" v-model:value="userModel.Password" type="password" autocomplete="off"></ejs-textbox>
                </div>
            </div>
            <div class="row mb-2 gx-2">
                <div class="col-md-6 col-sm-6">
                    <label>Location</label>
                    <ejs-dropdownlist :dataSource="locations.dataSource" :query="query" :fields="locations.fields" :allowFiltering="true" filterType="Contains" placeholder="Select a location" v-model:value="userModel.LocationId" popupWidth="Auto" :showClearButton="true" sortOrder='Ascending'></ejs-dropdownlist>
                </div>
                <div class="col-md-6" col-sm-6>
                    <label>Role</label>
                    <ejs-dropdownlist :dataSource="roles.dataSource" :fields="roles.fields" :allowFiltering="true" filterType="Contains" name="Role" placeholder="Select a role" v-model:value="userModel.Role" popupWidth="Auto" sortOrder='Ascending'></ejs-dropdownlist>
                    <div id="error-element-Role"></div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col">
                    <div class="e-card">
                        <ejs-checkbox label="Requestor" v-model:checked="userModel.IsRequestor"></ejs-checkbox>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col">
                    <div class="e-card">
                        <ejs-checkbox label="Operator" v-model:checked="userModel.IsOperator"></ejs-checkbox>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="e-card">
                        <ejs-checkbox label="Prototype" v-model:checked="userModel.IsPrototype"></ejs-checkbox>
                    </div>
                </div>
            </div>
        </div>

    </ejs-dialog>
</template>

<script>
    import Toast from "../../../modules/Toast.js";
    import { DialogComponent } from "@syncfusion/ej2-vue-popups";
    import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";
    import { TextBoxComponent, FormValidator } from "@syncfusion/ej2-vue-inputs";
    import { CheckBoxComponent } from "@syncfusion/ej2-vue-buttons";
    import { Query, Predicate } from "@syncfusion/ej2-data";
    import UserTemplate from '../../templates/UserTemplate.vue';
    import Spinner from '../../shared/Spinner.vue';

    export default {
        name: 'UserForm',
        inject: ['ApiClient', 'state'],
        expose: ['show'],
        emits: ['saved'],
        components: {
            'ejs-dialog': DialogComponent,
            'ejs-dropdownlist': DropDownListComponent,
            'ejs-textbox': TextBoxComponent,
            'ejs-checkbox': CheckBoxComponent,
            UserTemplate,
            Spinner
        },
        data() {
            return {
                userModel: {},
                newUser: true,
                options: {
                    rules: {
                        Role: { required: [true, "Employee role is required"] }
                    },
                },
                users: {
                    dataSource: this.ApiClient.DataSource("Listing/Users"),
                    query: new Query().addParams('Active', false).take(50),
                    fields: {
                        text: 'FullName',
                        value: 'GlobalUserName'
                    },
                    filtering: (e) => {
                        let ds = this.ApiClient.DataSource("Listing/Users");
                        let query = new Query().addParams('Active', false).take(50);
                        let pred = new Predicate(true);

                        if (e.text != null && e.text != '') {
                            pred = new Predicate('FullName', 'contains', e.text, true);
                            pred = pred.or('NetidDisplay', 'contains', e.text, true);
                            pred = pred.or('Email', 'contains', e.text, true);
                        }

                        query = query.where(pred);

                        e.updateData(ds, query);
                    },
                    change: (args) => {
                        if (args.itemData) {
                            this.userModel = args.itemData;
                            this.userModel.Password = null;
                        }
                    }
                },
                query: null,
                dialogVisible: false,
                formObj: {},
                position: { X: "center", Y: "top" },
                saving: false,
                tempNetid: null
            }
        },
        mounted() {
            this.state.GetDropdowns();
        },
        computed: {
            header() {
                if (this.newUser) {
                    if (this.userModel?.FullName) {
                        return "Add User " + (this.userModel?.FullName ?? "");
                    }
                    else {
                        return 'Add User';
                    }
                }
                else {
                    return "Update User " + (this.userModel?.FullName ?? "");
                }
            },
            avatar() {
                if (this.userModel?.Avatar) {
                    return "data:image/jpeg;base64," + this.userModel?.Avatar;
                }
                return "/img/profile.png";
            },
            buttons() {
                return [
                    { click: this.saveUser, buttonModel: { content: 'Save', isPrimary: true, disabled: this.saving } },
                    { click: this.closeDialog, buttonModel: { content: 'Close' } }
                ]
            },
            locations() {
                return {
                    dataSource: this.state.data.Dropdowns.Locations,
                    fields: { text: "Name", value: "Id" }
                };
            },
            roles() {
                return {
                    dataSource: this.state.data.Dropdowns.Roles,
                    fields: { text: "Key", value: "Value" },
                };
            },
        },
        methods: {
            show: function (user) {
                this.tempNetid = null;
                if (this.$refs.ddlNewUser) {
                    this.$refs.ddlNewUser.clear();
                }
                if (user) {
                    this.newUser = false;
                    this.ApiClient.Get('User/' + user.GlobalUserName, (response) => {
                        response.Password = null;
                        this.userModel = response;
                        this.query = this.state.predicateBuilder(this.userModel.LocationId);
                        this.dialogVisible = true;
                    });
                }
                else {
                    this.newUser = true;
                    this.userModel = this.newUserModel();
                    this.query = this.state.predicateBuilder();
                    this.dialogVisible = true;
                }
            },
            saveUser: function () {
                if (!this.formObj.validate()) {
                    Toast.Error("Please ensure all inputs are valid");
                    return;
                }

                if (this.tempNetid) {
                    this.userModel.Netid = this.tempNetid;
                }

                this.saving = true;
                this.ApiClient.Put('User/' + this.userModel.GlobalUserName, this.userModel, () => {
                    this.dialogVisible = false;
                    this.$emit('saved');
                }, () => {
                    this.saving = false;
                });
            },
            closeDialog: function () {
                this.dialogVisible = false;
            },
            dialogOpened: function () {
                this.formObj = new FormValidator("#form-element", this.options)
            },
            newUserModel: function () {
                return { FirstName: "", LastName: "", NewNetid: '', Netid: "", Email: "", Role: null, Location: null, IsOperator: false, IsRequestor: false, IsPrototype: false };
            },
            beforeClose: function () {
                this.closeDialog();
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .e-card {
        text-align: left;
        padding: 10px
    }
</style>
